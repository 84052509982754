import React from "react"
import { FiftyFiftyItem } from "./FiftyFiftyItem"
import {
  CarouselContainer,
  StyledSlider,
  ItemContainer,
} from "./MobileCarousel.styles"
import { $HasContent, FiftyFiftyBlockType } from "typings/modules"
import {
  SanityFiftyFiftyText,
  SanityFiftyFiftyMedia,
  SanityFiftyFiftyCard,
} from "typings/graphql"

type itemsType = {
  items: FiftyFiftyBlockType[]
} & $HasContent

type sItems = {
  media: SanityFiftyFiftyMedia | SanityFiftyFiftyCard
  text: SanityFiftyFiftyText
}

export const MobileCarousel = ({ items, $hasContent }: itemsType) => {
  const textItems = items.filter(
    item => item.__typename === "SanityFiftyFiftyText"
  )
  const assetItems = items.filter(
    item => item.__typename !== "SanityFiftyFiftyText"
  )
  const slideshowItems = assetItems.map(
    (item, index) =>
      ({
        media: item,
        text: textItems[index],
      } as sItems)
  )
  return (
    <CarouselContainer>
      <StyledSlider
        key={`slider-${items
          .map(item => item?.id?.toString().slice(0, 6))
          .join("")}`}
        dots
        arrows={false}
        infinite
        initialSlide={0}
        lazyLoad="ondemand"
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
      >
        {items &&
          slideshowItems.map(
            (item, index) =>
              item && (
                <ItemContainer
                  $bgColor={item.text?.bgColor?.opacityHex}
                  key={`slide-${index}`}
                >
                  {item.media && (
                    <FiftyFiftyItem
                      key={`${item?.media?.id}-${index}`}
                      item={item?.media}
                      $hasContent={$hasContent}
                    />
                  )}
                  {item?.text && (
                    <FiftyFiftyItem
                      key={`${item?.text?.id}-${index}`}
                      item={item?.text}
                      $hasContent={$hasContent}
                    />
                  )}
                </ItemContainer>
              )
          )}
      </StyledSlider>
    </CarouselContainer>
  )
}
