import React from "react"
import loadable from "@loadable/component"
import {
  FiftyFiftyItemProps,
  $MediaCardPair,
  $TextFormPair,
  $HasContent,
} from "typings/modules"
import { SanityFiftyFiftyMedia } from "typings/graphql"
const Text = loadable(() => import("./Text"))
const ResponsiveAsset = loadable(
  () => import("../ResponsiveAsset/ResponsiveAsset")
)
const Card = loadable(() => import("./Card"))
const Form = loadable(() => import("./Form"))

const Asset = ({ item }: { item: SanityFiftyFiftyMedia }) => (
  <ResponsiveAsset responsiveAsset={item?.asset} />
)

const componentMap = {
  SanityFiftyFiftyText: Text,
  SanityFiftyFiftyMedia: Asset,
  SanityFiftyFiftyCard: Card,
  SanityFiftyFiftyForm: Form,
}

type Props = FiftyFiftyItemProps & $MediaCardPair & $TextFormPair & $HasContent

export const FiftyFiftyItem = ({
  item,
  $mediaCardPair,
  $textFormPair,
  $hasContent,
}: Props) => {
  const display = item.__typename as keyof typeof componentMap
  const Component = display ? componentMap[display] : Text
  return (
    <Component
      item={item as any}
      $mediaCardPair={$mediaCardPair}
      $textFormPair={$textFormPair}
      $hasContent={$hasContent}
    />
  )
}
