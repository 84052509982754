import styled, { css } from "styled-components"
import Slider from "react-slick"
import { mq } from "styles"
import { $BgColor } from "typings/modules"

const dots = () => css`
  .slick-dots {
    top: auto;
    li {
      height: 16px;
      &:before {
        display: none;
      }
      button {
        cursor: pointer;
      }
    }
    bottom: 13px;
    ${mq.minWidth("md")} {
      bottom: 29px;
    }
    ${mq.minWidth("lg")} {
      bottom: 0;
    }
  }
  .slick-dots li button:before {
    content: "";
    cursor: pointer;
    font-size: 0px;
    line-height: normal;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
    border-radius: 50%;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: transparent;
    box-shadow: 0 0 0 3px
      ${({ theme }) => theme.localTheme?.text?.primary?.opacityHex};
  }
`

export const StyledSlider = styled(Slider)`
  position: relative;
  height: 100%;
  ${dots};
  .slick-track {
    display: flex;
    .slick-slide {
      height: auto;
      & > div {
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
`

export const CarouselContainer = styled.div`
  position: relative;
  padding-bottom: 43px;
  ${mq.minWidth("md")} {
    padding-bottom: 59px;
  }
`

export const ItemContainer = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  height: 100%;
`
