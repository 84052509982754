import React from "react"
import { SanityFiftyFiftyList } from "typings/graphql"
import { FiftyFiftyList } from "./FiftyFiftyList"

export const SanityFiftyFiftyListBlock = ({
  index,
  fields,
}: {
  fields: SanityFiftyFiftyList
  index: number
}) => <FiftyFiftyList index={index} {...fields} />

export default SanityFiftyFiftyListBlock
