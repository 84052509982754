import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { Grid } from "components/UI/Grid/Grid"
import { FiftyFiftyItem } from "components/FiftyFiftyItem/FiftyFiftyItem"
import { MobileCarousel } from "components/FiftyFiftyItem/MobileCarousel"
import { useCommonMediaQuery } from "hooks/useMediaQuery"
import { ModeType, FiftyFiftyBlockType } from "typings/modules"
import { SanityFiftyFiftyList } from "typings/graphql"
import { Container, Content } from "./FiftyFiftyList.styles"
import { PortableText } from "components/UI/PortableText/PortableText"

const isPair = (items: string[], item1: string, item2: string) =>
  items.includes(item1) && items.includes(item2)

// Sorts items list so there text is always under media and card
const sortItems = (items: FiftyFiftyBlockType[]) =>
  items?.map((item, index) => {
    const type = item?.__typename
    const isEven = index % 2 === 0
    const isText =
      type === "SanityFiftyFiftyText" || type === "SanityFiftyFiftyForm"
    const adjacentItem = isEven ? items[index + 1] : items[index - 1]
    const isAdjacentText =
      adjacentItem?.__typename === "SanityFiftyFiftyText" ||
      adjacentItem?.__typename === "SanityFiftyFiftyForm"
    if (
      (isEven && isText && !isAdjacentText) ||
      (!isEven && !isText && isAdjacentText)
    ) {
      return adjacentItem
    }
    return item
  })

type Props = {
  index: number
} & SanityFiftyFiftyList

export const FiftyFiftyList = (sanityProps: Props) => {
  const themeContext = useContext(ThemeContext)
  const { isTablet } = useCommonMediaQuery()
  const items = (sanityProps?.block || []) as FiftyFiftyBlockType[]
  const bgColor = sanityProps?.bgColor?.opacityHex
  const mode = sanityProps?.mode as ModeType
  const mobileLayout = sanityProps?.mobileLayout
  const content = sanityProps?._rawContent
  const sortedItems = isTablet ? sortItems(items) : items
  const itemsByTypename = items.map(item => item.__typename)
  const mediaCardPair = isPair(
    itemsByTypename,
    "SanityFiftyFiftyMedia",
    "SanityFiftyFiftyCard"
  )
  const textFormPair = isPair(
    itemsByTypename,
    "SanityFiftyFiftyText",
    "SanityFiftyFiftyForm"
  )

  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext[mode],
      }}
    >
      <Container $bgColor={bgColor}>
        {content && (
          <Grid base="1 [10] 1" md="2 [12] 2" lg="7 [10] 7">
            <Content>
              <PortableText blocks={content} $mode={mode} />
            </Content>
          </Grid>
        )}
        {isTablet && mobileLayout === "slideshow" ? (
          <MobileCarousel
            items={sortItems(sortedItems)}
            $hasContent={!!content}
          />
        ) : (
          <Grid base="[12]" md="[14]" lg="[12] [12]">
            {sortedItems &&
              sortedItems.map(
                (item, index) =>
                  item && (
                    <FiftyFiftyItem
                      key={`${item?.id}-${index}`}
                      item={item}
                      $mediaCardPair={mediaCardPair}
                      $textFormPair={textFormPair}
                      $hasContent={!!content}
                    />
                  )
              )}
          </Grid>
        )}
      </Container>
    </ThemeProvider>
  )
}
